/**
 * Return value of environment variable from .env file; throws
 * error if the variable is not present in the .env file.
 *
 * Return 'undefined' if the variable is present but completely
 * empty, e.g. `REACT_APP_INFURA_KEY=`
 */
export function getEnvVariable(name: string): string {
  const value = process.env[name]
  if (typeof value === 'undefined') {
    throw new Error(`${name} must be a defined environment variable`)
  }
  return value
}
