import { JsonRpcProvider } from '@ethersproject/providers'
import { getEnvVariable } from 'firefly/lib/utils/dotenv'
import { getRpcFeatureFlag } from 'firefly/lib/utils/rpc'

import { SupportedChainId } from './chains'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
if (typeof INFURA_KEY === 'undefined') {
  throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
}

// FF: This is only used to fetch token lists for
// the token select modal
export const MAINNET_PROVIDER = new JsonRpcProvider(`https://mainnet.infura.io/v3/${INFURA_KEY}`)

// FF: Get Manta's RPC URL from .env
let MANTA_RPC_URL = getEnvVariable('REACT_APP_MANTA_RPC_URL')

// FF Determine the RPC URL to use for Manta based on the 'rpc' feature flag
const MANTA_RPC_URL_FROM_FEATURE_FLAG = getRpcFeatureFlag()
if (MANTA_RPC_URL_FROM_FEATURE_FLAG) {
  console.log('Using RPC variant: ', MANTA_RPC_URL_FROM_FEATURE_FLAG)
  MANTA_RPC_URL = MANTA_RPC_URL_FROM_FEATURE_FLAG
}

/**
 * These are the network URLs used by the interface when there is not another available source of chain data
 */
export const RPC_URLS: { [key in SupportedChainId]: string } = {
  [SupportedChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.GOERLI]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.OPTIMISM]: `https://optimism-mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.OPTIMISTIC_KOVAN]: `https://optimism-kovan.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.ARBITRUM_ONE]: `https://arbitrum-mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.ARBITRUM_RINKEBY]: `https://arbitrum-rinkeby.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.MANTA]: MANTA_RPC_URL,
  [SupportedChainId.POLYGON]: `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.POLYGON_MUMBAI]: `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`,
}
