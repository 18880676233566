import { useLocationLinkProps } from 'hooks/useLocationLinkProps'
import { useMemo } from 'react'
import styled from 'styled-components/macro'

import { DEFAULT_LOCALE, SupportedLocale } from '../../constants/locales'
import { navigatorLocale, useActiveLocale } from '../../hooks/useActiveLocale'
import { ThemedText } from '../../theme'

const Container = styled(ThemedText.DeprecatedSmall)`
  position: absolute;
  bottom: 7px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-weight: 800 !important;
  font-size: 12px !important;
  line-height: 12px;
`

const useTargetLocale = (activeLocale: SupportedLocale) => {
  const browserLocale = useMemo(() => navigatorLocale(), [])

  if (browserLocale && (browserLocale !== DEFAULT_LOCALE || activeLocale !== DEFAULT_LOCALE)) {
    if (activeLocale === browserLocale) {
      return DEFAULT_LOCALE
    } else {
      return browserLocale
    }
  }
  return null
}

export function SwitchLocaleLink() {
  const activeLocale = useActiveLocale()
  const targetLocale = useTargetLocale(activeLocale)

  const { to } = useLocationLinkProps(targetLocale)

  if (!targetLocale || !to) return null

  // FF: Automatically show copyright for the current year
  const currentYear = new Date().getFullYear()
  return <Container>© {currentYear} Firefly</Container>
}
