/**
 * FF: Feature flag to switch between RPCs.
 *
 * The actual switching is done in src/firefly/lib/utils/rpc.ts
 */
import { FeatureFlag, useFeatureFlagsContext } from '../index'

// Variant to control the debug level
export enum RpcVariant {
  Default = 'default',
  Variant1 = 'variant 1',
  Variant2 = 'variant 2',
  Variant3 = 'variant 3',
}

// Needed to show the options in the modal
export function useRpcFlag(): RpcVariant {
  switch (useFeatureFlagsContext().flags[FeatureFlag.rpc]) {
    case 'default':
      return RpcVariant.Default
    case 'variant 1':
      return RpcVariant.Variant1
    case 'variant 2':
      return RpcVariant.Variant2
    case 'variant 3':
      return RpcVariant.Variant3
    default:
      return RpcVariant.Default
  }
}
