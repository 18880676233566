import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

// FF: Firefly v2 and v3 addresses
const MANTA_FIREFLY_V2_CORE_FACTORY_ADDRESSES = process.env.REACT_APP_MANTA_FIREFLY_V2_FACTORY_ADDRESS as string
const MANTA_FIREFLY_V2_ROUTER_ADDRESS = process.env.REACT_APP_MANTA_FIREFLY_V2_ROUTER_ADDRESS as string
const MANTA_FIREFLY_V3_CORE_FACTORY_ADDRESS = process.env.REACT_APP_MANTA_FIREFLY_V3_FACTORY_ADDRESS as string
const MANTA_FIREFLY_V3_SWAP_ROUTER_ADDRESS = process.env.REACT_APP_MANTA_FIREFLY_V3_SWAP_ROUTER_02_ADDRESS as string
const MANTA_FIREFLY_V3_MIGRATOR_ADDRESS = process.env.REACT_APP_MANTA_FIREFLY_V3_MIGRATOR_ADDRESS as string
const MANTA_FIREFLY_MULTICALL_ADDRESS = process.env.REACT_APP_MANTA_FIREFLY_V3_MULTICALL_ADDRESS as string
const MANTA_FIREFLY_QUOTER_ADDRESS = process.env.REACT_APP_MANTA_FIREFLY_V3_QUOTER_ADDRESS as string
const MANTA_FIREFLY_NONFUNGIBLE_POSITION_MANAGER_ADDRESS = process.env
  .REACT_APP_MANTA_FIREFLY_V3_NONFUNGIBLE_POSITION_MANAGER_ADDRESS as string
const MANTA_FIREFLY_TICK_LENS_ADDRESS = process.env.REACT_APP_MANTA_FIREFLY_V3_TICK_LENS_ADDRESSES as string

// FF: Object containing the addresses of V2 and V3 contracts
const MANTA_FIREFLY_ADDRESSES = {
  MANTA_FIREFLY_V2_CORE_FACTORY_ADDRESSES,
  MANTA_FIREFLY_V2_ROUTER_ADDRESS,
  MANTA_FIREFLY_V3_CORE_FACTORY_ADDRESS,
  MANTA_FIREFLY_V3_SWAP_ROUTER_ADDRESS,
  MANTA_FIREFLY_V3_MIGRATOR_ADDRESS,
  MANTA_FIREFLY_MULTICALL_ADDRESS,
  MANTA_FIREFLY_QUOTER_ADDRESS,
  MANTA_FIREFLY_NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
  MANTA_FIREFLY_TICK_LENS_ADDRESS,
}

// FF: Throw if a contract address is not defined, specifying which contract
Object.entries(MANTA_FIREFLY_ADDRESSES).forEach(([key, value]) => {
  if (!value) {
    throw new Error(`Contract ${key} is not defined in .env`)
  }
  if (!value.match(/^0x[a-fA-F0-9]{40}$/)) {
    throw new Error(`Invalid Ethereum address for ${key}: ${value}`)
  }
})

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')

export const V2_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V2_FACTORY_ADDRESS),
  // FF: Add Manta
  [SupportedChainId.MANTA]: MANTA_FIREFLY_V2_CORE_FACTORY_ADDRESSES,
}
export const V2_ROUTER_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'),
  // FF: Add Manta
  [SupportedChainId.MANTA]: MANTA_FIREFLY_V2_ROUTER_ADDRESS,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V3_FACTORY_ADDRESS, [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  // FF: Add Manta
  [SupportedChainId.MANTA]: MANTA_FIREFLY_V3_CORE_FACTORY_ADDRESS,
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xA5644E29708357803b5A882D272c41cC0dF92B34', [
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  // FF: Add Manta
  [SupportedChainId.MANTA]: MANTA_FIREFLY_V3_MIGRATOR_ADDRESS,
}

export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1F98415757620B543A52E61c46B32eB19261F984', [
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.OPTIMISM,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  [SupportedChainId.ARBITRUM_ONE]: '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xa501c031958F579dB7676fF1CE78AD305794d579',
  // FF: Add Manta
  [SupportedChainId.MANTA]: MANTA_FIREFLY_MULTICALL_ADDRESS,
}

/**
 * FF: This is the swap-router-contracts SwapRouter02 contract:
 * https://github.com/Uniswap/swap-router-contracts/blob/main/contracts/SwapRouter02.sol
 */
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON,
    SupportedChainId.POLYGON_MUMBAI,
  ]),
  // FF: Add Manta
  [SupportedChainId.MANTA]: MANTA_FIREFLY_V3_SWAP_ROUTER_ADDRESS,
}

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}

/**
 * FF: This version of the Uniswap interface (v4.99.0) still uses the Quoter
 * V1 contract.  On the other hand, the most recent version (v5) uses Quoter V2:
 * https://etherscan.io/address/0x61ffe014ba17989e743c5f6cb21bf9697530b21e#code
 *
 * The change was introduced in this PR:
 * https://github.com/Uniswap/interface/pull/5908/files
 *
 * It seems the change simply involves an update of the address, so
 * we should be fine using the QuoterV2 contract on this version of
 * the interface.
 */
export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  // FF: Add Manta
  [SupportedChainId.MANTA]: MANTA_FIREFLY_QUOTER_ADDRESS,
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xC36442b4a4522E871399CD717aBDD847Ab11FE88', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
    SupportedChainId.POLYGON_MUMBAI,
    SupportedChainId.POLYGON,
  ]),
  // FF: Add Manta
  [SupportedChainId.MANTA]: MANTA_FIREFLY_NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.ARBITRUM_ONE]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573',
  // FF: Add Manta
  [SupportedChainId.MANTA]: MANTA_FIREFLY_TICK_LENS_ADDRESS,
}
