import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { debugLog1 } from 'firefly/lib/utils/debugLog'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useMemo } from 'react'

import { useTokenContract } from './useContract'

/**
 * FF: Vanilla Uniswap function to get token allowance,
 * using the multicall contract.  Replaced with
 * src/firefly/hooks/useTokenAllowanceFromTokenContract.ts
 */
export function useTokenAllowance(token?: Token, owner?: string, spender?: string): CurrencyAmount<Token> | undefined {
  const contract = useTokenContract(token?.address, false)

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result

  if (token) {
    debugLog1('useTokenAllowance: allowance for token', token?.symbol, allowance?.toString())
  }

  return useMemo(
    () => (token && allowance ? CurrencyAmount.fromRawAmount(token, allowance.toString()) : undefined),
    [token, allowance]
  )
}
