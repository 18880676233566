import { useWeb3React } from '@web3-react/core'
import ConnectedAccountBlocked from 'components/ConnectedAccountBlocked'
// FF: Remove account risk check; that is, calls to
// screening-worker.uniswap.workers.dev used to determine
// whether an address should be blocked from using the interface
// import useAccountRiskCheck from 'hooks/useAccountRiskCheck'
import { useModalIsOpen } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'

export default function TopLevelModals() {
  // FF: Remove claim stuff
  // const addressClaimOpen = useModalIsOpen(ApplicationModal.ADDRESS_CLAIM)
  // const addressClaimToggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)

  const blockedAccountModalOpen = useModalIsOpen(ApplicationModal.BLOCKED_ACCOUNT)
  const { account } = useWeb3React()

  // useAccountRiskCheck(account)
  const open = Boolean(blockedAccountModalOpen && account)
  return (
    <>
      {/* FF: Remove claim stuff */}
      {/* <AddressClaimModal isOpen={addressClaimOpen} onDismiss={addressClaimToggle} /> */}
      <ConnectedAccountBlocked account={account} isOpen={open} />
    </>
  )
}
