import styled from 'styled-components/macro'

import { ReactComponent as AmazonIcon } from '../../assets/svg/amazon.svg'
import { ReactComponent as BerkleyIcon } from '../../assets/svg/berkley.svg'
import { ReactComponent as TwitterIcon } from '../../assets/svg/twitter.svg'
import { ReactComponent as UniversityPorIcon } from '../../assets/svg/university-portsmouth.svg'
import SectionFourIMG from '../../assets/videos/sectionFour.mp4'

const MEDIUM_MEDIA_BREAKPOINT = '720px'

const CenteredWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  color: #fefefe;
  justify-content: center;
  background-color: #151f49;
`

const HeadingText = styled.h1`
  font-size: 60px;
  font-weight: 800;
  line-height: 72.61px;
  letter-spacing: -0.05em;
  text-align: left;
  margin: 0;
  background: linear-gradient(275.82deg, #ffffff 12.18%, #c0aae0 97.28%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  z-index: 1;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    font-size: 24px;
    padding: 0 15px;
    line-height: 29px;
    margin-top: -32px;
    z-index: 2;
    br {
      display: none;
    }
  }
`

const ParagraphText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  letter-spacing: -0.04em;
  text-align: left;
  color: #fefefe;
  z-index: 1;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    font-size: 14px;
    padding: 0 15px;
    line-height: 29px;
    margin-top: 16px;
    br {
      display: none;
    }
  }
`

const SpanText = styled.span`
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  line-height: 20px;
  letter-spacing: -0.04em;
  color: #fefefe;
  z-index: 1;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    font-size: 14px;
    padding: 0 15px;
    line-height: 29px;
    margin-top: 16px;
    br {
      display: none;
    }
  }
`

const MaxWidth = styled.div`
  max-width: 100%;
  justify-content: space-around;
  display: flex;
  z-index: 1;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    gap: 0;
    justify-content: normal;
    flex-direction: column;
    height: 100%;
  }
`

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 36px;
  width: 40%;
  z-index: 1;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    gap: 12px;
    width: 100%;
  }
`

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 48px;
  margin-bottom: 36px;
  svg {
    max-height: 60px;
    max-width: 125px;
    &:first-of-type {
      max-height: 48px;
    }
  }
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    gap: 20px;
    padding: 0 15px;
    svg {
      max-width: 60px;
      height: fit-content;
      &:first-of-type {
        max-height: 22px;
      }
    }
  }
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    position: relative;
    height: 280px;
  }
`

const SectionFour = () => {
  return (
    <CenteredWrap>
      <MaxWidth>
        <StyledVideo playsInline autoPlay loop muted src={SectionFourIMG} />
        <HeadingText>
          The Team to <br /> Get It Done
        </HeadingText>
        <ColumnDiv>
          <ParagraphText>
            Our team has shipped products at leading high-tech companies to millions. Our smart contract engineers have
            extensive experience on Ethereum and Solana. In crypto, team strength varies widely in terms of work
            history. Firefly has the team to deliver.
          </ParagraphText>
          <ParagraphText>(Our identities have been shared with the Manta team who can verify)</ParagraphText>
          <SpanText>Our team has worked for and attended:</SpanText>
          <IconsWrapper>
            <TwitterIcon />
            <AmazonIcon />
            <UniversityPorIcon />
            <BerkleyIcon />
          </IconsWrapper>
        </ColumnDiv>
      </MaxWidth>
    </CenteredWrap>
  )
}

export default SectionFour
