import { FeatureFlag } from 'featureFlags'
import { RpcVariant } from 'featureFlags/flags/rpc'

import { getEnvVariable } from './dotenv'
import { getFeatureFlagValue } from './featureFlags'

/**
 * Return the RPCs associated to the 'rpc' feature flag.
 *
 * Return null for the default RPC.
 */
export function getRpcFeatureFlag(): string | null {
  const flagValue = getFeatureFlagValue(FeatureFlag.rpc)
  switch (flagValue) {
    case RpcVariant.Default:
      return null
    case RpcVariant.Variant1:
      const RPC_VARIANT_1 = getEnvVariable('REACT_APP_MANTA_RPC_URL_VARIANT_1')
      console.log('Using RPC_VARIANT_1: ', RPC_VARIANT_1)
      return RPC_VARIANT_1
    case RpcVariant.Variant2:
      const RPC_VARIANT_2 = getEnvVariable('REACT_APP_MANTA_RPC_URL_VARIANT_2')
      console.log('Using RPC_VARIANT_2: ', RPC_VARIANT_2)
      return RPC_VARIANT_2
    case RpcVariant.Variant3:
      const RPC_VARIANT_3 = getEnvVariable('REACT_APP_MANTA_RPC_URL_VARIANT_3')
      console.log('Using RPC_VARIANT_3: ', RPC_VARIANT_3)
      return RPC_VARIANT_3
    default:
      return null
  }
}
