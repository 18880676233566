import JSBI from 'jsbi'

// FF: Update V2 factory address
// export const FACTORY_ADDRESS = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f'
export const FACTORY_ADDRESS = '0x64FF3B0Dfa2e3F46B4777dbAc310F29E06146808'

// FF: Update init code hash for V2 pairs
// export const INIT_CODE_HASH = '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f'
export const INIT_CODE_HASH = '0xe18d506bdf6c26150a9f9b08a8db1387f637b3156f5f2153ac6590f081824647'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)
