import { FeatureFlag } from 'featureFlags'
import { DebugVariant } from 'featureFlags/flags/debug'

import { getFeatureFlagValue } from './featureFlags'

/**
 * Wrapper to console.log with a debug level
 */
export function debugLogWithLevel(level: number, ...args: any[]) {
  if (process.env.NODE_ENV === 'development' && getDebugLevel() >= level) {
    console.log(...args)
  }
}

/**
 * Log to debug, with debug level 1
 */
export function debugLog1(...args: any[]) {
  debugLogWithLevel(1, ...args)
}

/**
 * Log to debug, with debug level 2
 */
export function debugLog2(...args: any[]) {
  debugLogWithLevel(2, ...args)
}

/**
 * Log to debug, with debug level 3
 */
export function debugLog3(...args: any[]) {
  debugLogWithLevel(3, ...args)
}

/**
 * Shorthand to log to debug, with debug level 1
 */
export const debugLog = debugLog1

/**
 * Get the value of the 'debug' feature flag as a number
 * from 0 to 3, where 0 is off and 3 is the highest level
 */
export function getDebugLevel(): 0 | 1 | 2 | 3 {
  const flagValue = getFeatureFlagValue(FeatureFlag.debug)
  switch (flagValue) {
    case DebugVariant.Off:
      return 0
    case DebugVariant.One:
      return 1
    case DebugVariant.Two:
      return 2
    case DebugVariant.Three:
      return 3
    default:
      return 0
  }
}
