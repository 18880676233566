/**
 * Helpers to access the values in the Feature Flags modal
 * Screenshot > https://d.pr/i/If0mmA + https://d.pr/i/rzl0J0
 */

import { BaseVariant, FeatureFlag } from 'featureFlags'
import { DebugVariant } from 'featureFlags/flags/debug'
import { RpcVariant } from 'featureFlags/flags/rpc'

/**
 * Default values for feature flags, as inferred from
 * src/featureFlags/index.tsx
 */
const DEFAULT_FEATURE_FLAGS = {
  [FeatureFlag.explore]: BaseVariant.Control,
  [FeatureFlag.navBar]: BaseVariant.Control,
  [FeatureFlag.phase1]: BaseVariant.Control,
  [FeatureFlag.redesign]: BaseVariant.Control,
  [FeatureFlag.tokenSafety]: BaseVariant.Control,
  // Firefly feature flags
  [FeatureFlag.rpc]: RpcVariant.Default,
  [FeatureFlag.debug]: DebugVariant.Off,
}

/**
 * Get feature flags from the local storage.  Feature flags
 * that are not defined will be set to their default values.
 */
export function getFeatureFlags(): Record<FeatureFlag, BaseVariant | DebugVariant | RpcVariant> {
  const featureFlags = localStorage.getItem('featureFlags')
  if (!featureFlags) {
    return DEFAULT_FEATURE_FLAGS
  }
  // Merge with default values to ensure all flags are set
  return { ...DEFAULT_FEATURE_FLAGS, ...JSON.parse(featureFlags) }
}

/**
 * Get the value of the given feature flag from the local storage,
 * or undefined if the flag is not set
 */
export function getFeatureFlagValue(flag: FeatureFlag): BaseVariant | DebugVariant | RpcVariant {
  return getFeatureFlags()[flag]
}
