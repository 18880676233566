import styled from 'styled-components/macro'

import { ReactComponent as CheckVector } from '../../assets/svg/check-vector.svg'
import sectionThreeVideo from '../../assets/videos/sectionThree.mp4'

const MEDIUM_MEDIA_BREAKPOINT = '720px'

const CenteredWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fefefe;
  background-color: #291448;
  @media screen and (max-width: 500px) {
    justify-content: normal;
  }
`

const HeadingText = styled.h1`
  font-size: 60px;
  font-weight: 800;
  line-height: 72.61px;
  letter-spacing: -0.05em;
  margin: 0;
  margin-bottom: 16px;
  text-align: left;
  width: fit-content;
  background: linear-gradient(272.53deg, #ffffff 1.04%, #c0aae0 98%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    margin-top: 6px;
    font-size: 24px;
    line-height: 29px;
  }
`

const SubHeadingText = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 32.78px;
  letter-spacing: -0.04em;
  color: #fefefe;
  margin: 0;
  margin-bottom: 32px;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    font-size: 16px;
    margin-bottom: 16px;
  }
`

const TextDiv = styled.div`
  position: absolute;
  left: 50%;
  z-index: 99;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    position: relative;
    left: 0;
    padding: 0 15px 36px 15px;
  }
`

const RowDiv = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 16px;
  svg {
    width: 28px;
    height: 28px;
  }
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
  }
`

const SpanText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.04em;
  text-align: left;
  margin: 0;
  color: #fefefe;
  flex: 1;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    line-height: 20px;
    font-size: 14px;
  }
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    position: relative;
    height: 280px;
    margin-bottom: -40px;
  }
`

const VideoOverlay = styled.div`
  display: none;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    display: block;
    background: linear-gradient(180deg, #291448 7.4%, rgba(41, 20, 72, 0) 50%),
      linear-gradient(180deg, rgba(41, 20, 72, 0) 50%, #291448 92%),
      linear-gradient(270deg, #291448 0.8%, rgba(41, 20, 72, 0) 47.8%);
    height: 60%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const SectionThree = () => {
  return (
    <CenteredWrap>
      <VideoOverlay />
      <StyledVideo playsInline src={sectionThreeVideo} autoPlay muted loop />
      <TextDiv>
        <HeadingText>
          We Succeed When <br /> You Succeed
        </HeadingText>
        <SubHeadingText>Our users are our customers. Not VC&apos;s.</SubHeadingText>
        <RowDiv>
          <CheckVector />
          <SpanText>
            Firefly offers 24/7 Support to dex users - get a fast response to <br /> your problem any hour of the day.
          </SpanText>
        </RowDiv>
        <RowDiv>
          <CheckVector />
          <SpanText>And 30% FLY tokens are going to community</SpanText>
        </RowDiv>
      </TextDiv>
    </CenteredWrap>
  )
}

export default SectionThree
