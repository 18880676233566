import { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'

import CoinsVideo from '../../assets/videos/coins.mp4'

const MEDIUM_MEDIA_BREAKPOINT = '720px'

const CenteredWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  color: #fefefe;
  align-items: center;
`

const ShadowText = styled.h1`
  font-size: 60px;
  margin: 0;
  font-weight: 800;
  line-height: 72.61px;
  letter-spacing: -0.04em;
  text-align: left;
  width: fit-content;
  background: linear-gradient(274.61deg, #fcc768 6.36%, #f5e0ba 99.45%);
  text-shadow: 0px 9px 40px 0px #00000026;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  z-index: 2;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    text-align: left;
    font-size: 24px;
    line-height: 29px;
    br {
      display: none;
    }
  }
`
const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    position: relative;
    max-height: 320px;
    z-index: 1;
  }
`

const RowDiv = styled.div`
  display: flex;
  gap: 48px;
  margin-top: 95px;
  z-index: 2;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    flex-direction: column;
    margin-top: 12px;
    text-align: center;
    background: #0d0600;
    box-shadow: inset 0em 5em 24px #0d0600;
    width: 100%;
    padding: 16px 15px 36px 15px;
    margin: 0;
    gap: 12px;
  }
`

const BackgroundOverlay = styled(CenteredWrap)`
  background: #0D0600;
}
`

const ClassicText = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 32.78px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #ffecc8;
  margin: 0;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    text-align: left;
    font-size: 14px;
    line-height: 19px;
  }
`

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    gap: 12px;
  }
`

const VideoOverlay = styled.div`
  display: none;
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    display: block;
    background: linear-gradient(180deg, #221200 0%, rgba(86, 45, 0, 0) 32.83%),
      linear-gradient(180deg, rgba(13, 6, 0, 0) 50.04%, #0d0600 72.56%),
      linear-gradient(90.24deg, #0d0600 0.21%, rgba(13, 6, 0, 0) 26.06%),
      linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 30.13%);
    height: 60%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const SectionTwo = () => {
  const videoRef = useRef(null)

  useEffect(() => {
    const interval = setInterval(() => videoRef.current.play(), 7000)
    videoRef.current.addEventListener('ended', () => {
      videoRef.current.currentTime = 0
    })
    return () => clearInterval(interval)
  }, [])
  return (
    <BackgroundOverlay>
      <VideoOverlay />
      <StyledVideo playsInline ref={videoRef} autoPlay muted src={CoinsVideo} />
      <RowDiv>
        <ShadowText>
          Loaded to the Brim <br /> with Features for <br /> Traders & LPs
        </ShadowText>
        <ColumnDiv>
          <ClassicText>Concentrated Liquidity</ClassicText>
          <ClassicText>Flexibile Liqudity</ClassicText>
          <ClassicText>Order-Book type orders</ClassicText>
          <ClassicText>Single Sided Liquidity</ClassicText>
          <ClassicText>Limit Buy and Sell orders</ClassicText>
        </ColumnDiv>
      </RowDiv>
    </BackgroundOverlay>
  )
}

export default SectionTwo
