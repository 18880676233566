/**
 * FF: add debug flag to print stuff on console.log
 */
import { FeatureFlag, useFeatureFlagsContext } from '../index'

// Variant to control the debug level
export enum DebugVariant {
  Off = 'off',
  One = 'level 1',
  Two = 'level 2',
  Three = 'level 3',
}

// Needed to show the options in the modal
export function useDebugFlag(): DebugVariant {
  switch (useFeatureFlagsContext().flags[FeatureFlag.debug]) {
    case 'off':
      return DebugVariant.Off
    case 'level 1':
      return DebugVariant.One
    case 'level 2':
      return DebugVariant.Two
    case 'level 3':
      return DebugVariant.Three
    default:
      return DebugVariant.Off
  }
}
