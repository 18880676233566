import { BaseProvider, JsonRpcProvider } from '@ethersproject/providers'
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { Protocol } from '@uniswap/router-sdk'
import { BaseGasModelFactory, ChainId } from '@uniswap/smart-order-router'
import { AlphaRouterParams } from '@uniswap/smart-order-router'
import { V3SubgraphProvider } from '@uniswap/smart-order-router'
import { V2SubgraphProvider } from '@uniswap/smart-order-router'
import { RPC_URLS } from 'constants/networks'
import { getClientSideQuote, toSupportedChainId } from 'lib/hooks/routing/clientSideSmartOrderRouter'
import ms from 'ms.macro'
import qs from 'qs'

import { GetQuoteResult } from './types'

const routerProviders = new Map<ChainId, BaseProvider>()
function getRouterProvider(chainId: ChainId): BaseProvider {
  const provider = routerProviders.get(chainId)
  if (provider) return provider

  const supportedChainId = toSupportedChainId(chainId)
  if (supportedChainId) {
    const provider = new JsonRpcProvider(RPC_URLS[supportedChainId])
    routerProviders.set(chainId, provider)
    return provider
  }

  throw new Error(`Router does not support this chain (chainId: ${chainId}).`)
}

// FF TODO: Temporary disable V2 routes
// const protocols: Protocol[] = [Protocol.V2, Protocol.V3, Protocol.MIXED]
const protocols: Protocol[] = [Protocol.V3]

const DEFAULT_QUERY_PARAMS = {
  protocols: protocols.map((p) => p.toLowerCase()).join(','),
  // example other params
  // forceCrossProtocol: 'true',
  // minSplits: '5',
}

export const routingApi = createApi({
  reducerPath: 'routingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.uniswap.org/v1/',
  }),
  endpoints: (build) => ({
    getQuote: build.query<
      GetQuoteResult,
      {
        tokenInAddress: string
        tokenInChainId: ChainId
        tokenInDecimals: number
        tokenInSymbol?: string
        tokenOutAddress: string
        tokenOutChainId: ChainId
        tokenOutDecimals: number
        tokenOutSymbol?: string
        amount: string
        useClientSideRouter: boolean // included in key to invalidate on change
        type: 'exactIn' | 'exactOut'
      }
    >({
      async queryFn(args, _api, _extraOptions, fetch) {
        const { tokenInAddress, tokenInChainId, tokenOutAddress, tokenOutChainId, amount, useClientSideRouter, type } =
          args

        let result

        try {
          if (useClientSideRouter) {
            const chainId = args.tokenInChainId
            // FF: Original uniswap code passes only two parameters when instantiating
            // the alpharouter:
            // const params = { chainId, provider: getRouterProvider(chainId) }
            // By doing this, the interface delegates to the smart-order-router all
            // choices regarding pool fetching from subgraphs.  Here we expand all
            // arguments and make the following modifications
            // - Always use live V3 subgraph provider instead of cached fallback
            // - Optionally use a simpler gas model for V3, to speed up swap quotes
            const params: AlphaRouterParams = {
              chainId,
              provider: getRouterProvider(chainId),
              // multicall2Provider,
              v3SubgraphProvider: new V3SubgraphProvider(chainId),
              // onChainQuoteProvider,
              // v3PoolProvider,
              v2SubgraphProvider: new V2SubgraphProvider(chainId),
              // v2PoolProvider,
              // v2QuoteProvider,
              // tokenProvider,
              // gasPriceProvider,
              v3GasModelFactory:
                process.env.REACT_APP_SOR_USE_BASE_GAS_MODEL_FOR_V3 === 'true' ? new BaseGasModelFactory() : undefined,
              // v2GasModelFactory,
              // mixedRouteGasModelFactory,
              // blockedTokenListProvider,
              // swapRouterProvider,
              // optimismGasDataProvider,
              // tokenValidatorProvider,
              // arbitrumGasDataProvider,
            }
            result = await getClientSideQuote(args, params, { protocols })
          } else {
            const query = qs.stringify({
              ...DEFAULT_QUERY_PARAMS,
              tokenInAddress,
              tokenInChainId,
              tokenOutAddress,
              tokenOutChainId,
              amount,
              type,
            })
            result = await fetch(`quote?${query}`)
          }

          return { data: result.data as GetQuoteResult }
        } catch (e) {
          // TODO: fall back to client-side quoter when auto router fails.
          // deprecate 'legacy' v2/v3 routers first.
          return { error: e as FetchBaseQueryError }
        }
      },
      keepUnusedDataFor: ms`10s`,
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetQuoteQuery } = routingApi
