import styled from 'styled-components/macro'

import SectionFour from '../../components/AboutUs/SectionFour'
import SectionOne from '../../components/AboutUs/SectionOne'
import SectionThree from '../../components/AboutUs/SectionThree'
import SectionTwo from '../../components/AboutUs/SectionTwo'

const AboutUsPageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
`

const BackgroundWrapper = styled.section`
  width: 100%;
  height: 100vh;
  scroll-snap-align: center;
  position: relative;
  @media screen and (max-width: 500px) {
    height: ${(props) => (props.responsiveHeight ? props.responsiveHeight : '100vh')};
  }
`
export const CenteredWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fefefe;
`

export const HeadingText = styled.h1`
  font-size: 60px;
  font-weight: 800;
  line-height: 72.61px;
  letter-spacing: -0.05em;
  margin: 0;
  text-align: center;
  z-index: 1;
  @media screen and (max-width: 500px) {
    font-size: 28px;
    line-height: 26px;
  }
`

export const HeadingSmallText = styled.span`
  font-size: 40px;
  font-weight: 600;
  line-height: 48.41px;
  letter-spacing: -0.05em;
  text-align: center;
  color: #fefefe;
  z-index: 1;
  @media screen and (max-width: 500px) {
    font-size: 22px;
    line-height: 26px;
  }
`

export const ParagraphText = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #fefefe;
  margin: 0;
  margin-top: 16px;
  z-index: 1;
  @media screen and (max-width: 500px) {
    font-size: 14px;
    line-height: 19px;
  }
`

const AboutUsPage = () => {
  return (
    <AboutUsPageWrapper>
      <BackgroundWrapper responsiveHeight={`50vh`}>
        <SectionOne />
      </BackgroundWrapper>
      <BackgroundWrapper responsiveHeight={`unset`}>
        <SectionTwo />
      </BackgroundWrapper>
      <BackgroundWrapper responsiveHeight={`unset`}>
        <SectionThree />
      </BackgroundWrapper>
      <BackgroundWrapper responsiveHeight={`unset`}>
        <SectionFour />
      </BackgroundWrapper>
    </AboutUsPageWrapper>
  )
}

export default AboutUsPage
