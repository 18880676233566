import { useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ReactComponent as FireflyLogoSmall } from '../../assets/svg/firefly-logo-small.svg'
import { ReactComponent as FireflyLogo } from '../../assets/svg/logo.svg'
import CarVideo from '../../assets/videos/car.mp4'
import { HeadingSmallText, HeadingText, ParagraphText } from '../../pages/AboutUs'

const FireflyLogoWrap = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 28px;
  transform: translateX(-50%, -50%);
  @media (max-width: 500px) {
    left: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    top: 0;
    padding: 8px 0;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const CenteredWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fefefe;
  position: relative;
  background-color: #000;
`

const HomeLink = styled(NavLink)`
  position: absolute;
  top: 28px;
  left: 28px;
  color: #fefefe;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.04em;
  z-index: 1;
  @media (max-width: 500px) {
    display: none;
  }
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
`

const SectionOne = () => {
  const videoRef = useRef(null)

  useEffect(() => {
    const interval = setInterval(() => videoRef.current.play(), 7000)
    videoRef.current?.addEventListener('ended', () => {
      videoRef.current.currentTime = 0
    })
    return () => clearInterval(interval)
  }, [])

  return (
    <CenteredWrap>
      <HomeLink to={'/swap'}>
        <FireflyLogoSmall height={30} />
      </HomeLink>
      <StyledVideo playsInline ref={videoRef} autoPlay muted src={CarVideo} />
      <FireflyLogoWrap>
        <FireflyLogo width={150} height={67} />
      </FireflyLogoWrap>
      <HeadingText>The Fastest Dex in the West</HeadingText>
      <HeadingSmallText>(and East)</HeadingSmallText>
      <ParagraphText>
        Powered by cutting-edge Modular tech,
        <br />
        Firefly transactions fly
      </ParagraphText>
    </CenteredWrap>
  )
}

export default SectionOne
